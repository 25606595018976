<style>
#count-bales {

    font-weight: 700;
    letter-spacing: 1px;
    font-size: 1.1vw;
}

#total-mass {
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 1.1vw;
}

.grade-card {
    stack-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;
}
</style>
<template>
    <div>

        <b-card no-body class="mb-2">
            <div class="m-2">
                <b-row class="mt-1">
                    <b-col>
                        <b-form-group labe-for="region_id" label="Region">
                            <validation-provider #default="{ errors }" name="Region" rules="">
                                <b-form-select id="region_id" name="region_id" size="md" v-model="stackGrade.region_id"
                                    @change="changeRegion(stackGrade.region_id)">
                                    <b-form-select-option :value="null">Select...</b-form-select-option>
                                    <b-form-select-option v-for="location in locations" :key="location.id"
                                        :value="location">
                                        {{ location.name.toUpperCase() }}
                                    </b-form-select-option>
                                </b-form-select>
                                <small class="text-danger">{{ errors[0] }}</small>
                                <small class="text-danger" v-if="serverErrors && serverErrors.region_id">{{
                                    serverErrors.region_id[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <b-col>
                        <b-form-group labe-for="location_id" label="Area">
                            <validation-provider #default="{ errors }" name="Area" rules="">
                                <b-form-select id="location_id" name="location_id" size="md"
                                    v-model="stackGrade.location_id" @change="getWarehouse(stackGrade.location_id)">
                                    <b-form-select-option :value="null">Select...</b-form-select-option>
                                    <b-form-select-option v-for="location in locaChildren" :key="location.id"
                                        :value="location.id">
                                        {{ location.name.toUpperCase() }}
                                    </b-form-select-option>
                                </b-form-select>
                                <small class="text-danger">{{ errors[0] }}</small>
                                <small class="text-danger" v-if="serverErrors && serverErrors.location_id">{{
                                    serverErrors.location_id[0] }}</small>
                            </validation-provider>
                        </b-form-group>

                    </b-col>

                    <b-col>
                        <b-form-group labe-for="name" label="Warehouse">
                            <validation-provider #default="{ errors }" name="Warehouse" rules="">
                                <b-form-select id="name" name="name" size="md" v-model="stackGrade.warehouse_id"
                                    @change="getStack(stackGrade.warehouse_id)">
                                    <b-form-select-option :value="null">Select...</b-form-select-option>
                                    <b-form-select-option v-for="warehouse in warehouses" :key="warehouse.id"
                                        :value="warehouse.id">
                                        {{ warehouse.name.toUpperCase() }}  ({{ warehouse.code }})
                                    </b-form-select-option>
                                </b-form-select>
                                <small class="text-danger">{{ errors[0] }}</small>
                                <small class="text-danger" v-if="serverErrors && serverErrors.warehouse_id">{{
                                    serverErrors.warehouse_id[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                </b-row>
            </div>

        </b-card>


        <b-row>
            <b-col v-for="(card, index) in cards" :key="index" cols="4" md="4" class="mb-2">
                <b-card :style="{ backgroundColor: card.bgColor }" no-body class="mb-0 grade-card">
                    <div class="m-2">
                        <b-row>
                            <b-col cols="2" md="2">
                                <div class="text-center">
                                    <h1 style="font-size:5vw">{{ card.letter }}</h1>
                                </div>
                            </b-col>
                            <b-col cols="5" md="5">
                                <div class="text-center mt-2">
                                    <p id="count-bales">{{ card.countBales }} <small>Bales</small></p>
                                    <p id="total-mass">{{ card.totalMass }} <small>Kg</small></p>
                                </div>
                            </b-col>
                            <b-col cols="5" md="5">
                                <div class="text-center">
                                    <ul class="list-group">
                                        <li class="list-group-item" style="letter-spacing: 2px">{{ card.items }}</li>
                                    </ul>
                                </div>
                            </b-col>
                        </b-row>
                        <!-- ./Table Top -->
                    </div>
                </b-card>
            </b-col>

        </b-row>

    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
    BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
    BForm, BFormGroup,
    BButton, BFormSelect, BFormSelectOption, BFormInput, BDropdown, BDropdownItem,
    BSpinner, BBadge, BAlert, BListGroup
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'
import store from '@/store'
import { ref, onUnmounted, onMounted, reactive } from '@vue/composition-api'

import stackSummaryStoreModule from '@/views/cromis/warehouse/stack_summary/stackSummaryStoreModule'
import useStackSummaryList from '@/views/cromis/warehouse/stack_summary/useStackSummaryList'


export default {
    props: {},
    components: {
        BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
        BForm, BFormGroup,
        BButton, BFormSelect, BFormSelectOption, BFormInput, vSelect, BDropdown, BDropdownItem,
        BSpinner,
        ValidationObserver, ValidationProvider, BBadge, BAlert, BListGroup
    },
    directives: {},
    watch: {
        userInput(newInput, oldInput) {
            // Update default value when user input changes
            if (!isNaN(newInput)) {
                this.defaultValue -= (newInput - oldInput);
            } else {
                // Handle case where userInput is NaN (e.g., after deleting the number)
                this.defaultValue = this.defaultValue;
            }
        },
    },
    setup(props, context) {
        const dataForm = ref(null)
        const myModal = ref(null)
        const myRangeModal = ref(null)
        const saving = ref(false)
        const serverErrors = ref(null)
        const selections = ref([])
        const formSelections = ref([])
        const errosOcurs = ref(null)
        const levels = ref(null)
        const companies = ref([])
        const societies = ref([])
        const children = ref([])
        const locations = ref([])
        const locaChildren = ref([])
        const warehouses = ref([])
        const client = ref(JSON.parse(localStorage.getItem('userData')).client)
        const locId = ref(JSON.parse(localStorage.getItem('userData')).location)
        console.log(locId)
        const stack = ref({
            id: null,
            number: null,

        })


        const stackGrade = ref({
            id: null,
            location_id: null,
            warehouse_id: null,
            stack_id: null,
            region_id: null,
            market_id: null,
            group_id: null,
        })


        const changeRegion = async (event) => {
            console.log(event)
            locaChildren.value = event.children

            
            await store.dispatch('cromis-stack/getWarehouse', { location_id: event.id })
                .then(response => {
                    warehouses.value = response.data.warehouses
                    console.log(response)
                })
                .catch(error => {
                    console.log('got error', error)
                })

            // locationFilter.value = event.id
            // filter.value.stackGrade_id = event.id
        }

        const getWarehouse = async (location) => {

            await store.dispatch('cromis-stack/getWarehouse', { location_id: stackGrade.value.location_id })
                .then(response => {
                    warehouses.value = response.data.warehouses
                    console.log(response)
                })
                .catch(error => {
                    console.log('got error', error)
                })
        }

        const getStack = async (location) => {

            await store.dispatch('cromis-stack/getStack', { warehouse_id: stackGrade.value.warehouse_id })
                .then(response => {
                    stacks.value = response.data.warehouses
                    console.log(response)
                })
                .catch(error => {
                    console.log('got error', error)
                })
        }



        const CROMIS_STORE_MODULE_NAME = 'cromis-stack'
        // Register module
        if (!store.hasModule(CROMIS_STORE_MODULE_NAME)) store.registerModule(CROMIS_STORE_MODULE_NAME, stackSummaryStoreModule)

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(CROMIS_STORE_MODULE_NAME)) store.unregisterModule(CROMIS_STORE_MODULE_NAME)
        })

        onMounted(async () => {
            await store.dispatch('cromis-stack/locations')
                .then(response => {
                    locations.value = response.data.locations
                    populateSelections(response.data.locations)
                })
                .catch(error => {
                    console.log('got error', error)
                })

        })

        const populateSelections = (locations) => {
            for (let i = 0; i < locations.length; i++) {
                for (let x = 0; x < selections.value.length; x++) {
                    if (locations[i].level.name.toLowerCase() === selections.value[x].level) {
                        selections.value[x].locations.push(locations[i])

                        if (x === 0) {
                            formSelections.value[x].locations.push(locations[i])
                        }
                    }
                }
            }
        }


        const {
            fetch,
            columns,
            perPage,
            currentPage,
            totalRecords,
            from,
            to,
            meta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            records,
            refetch,
            resolveStatusVariant,
            // Filers
            locationFilter,
        } = useStackSummaryList()

        // Form Methods
        const invokeUpdateForm = (item) => {
            serverErrors.value = null
            errosOcurs.value = null

            stack.value = {
                id: item.id,
                type: item.type,
                number: item.number,
                ranges: item.ranges,
            }

            myModal.value.show()
        }

        const invokeCreateForm = () => {
            formSelections.value.map((form) => {
                form.model = null
            })

            stack.value = {
                id: null,
                number: null,

            }

            myModal.value.show()
        }


        const invokeRangeUpdateForm = (par) => {
            formSelections.value.map((form) => {
                form.model = null
            })

            console.log(par)

            range.value = {
                id: par.ranges[0].id,
                stack_id: par.id,
                number: par.number,
                start: par.ranges[0].start,
                end: par.ranges[0].end,
                quantity: par.ranges[0].quantity,
                hasMissing: null,
                lastBarcode: null,
                direction: null,
                count: null
            }

            myRangeModal.value.show()
        }

        const isFormValid = async () => {
            let isValid = false
            await dataForm.value.validate().then(success => {
                isValid = success
            })

            return isValid
        }

        const submit = (bvModalEvt) => {
            bvModalEvt.preventDefault()

            serverErrors.value = null
            // Handle form submit
            if (stack.value.id === null || stack.value.id === 0)
                handleCreate()
            else
                handleUpdate(stack.value)
        }

        const submitUpdateRange = (bvModalEvt) => {
            bvModalEvt.preventDefault()

            serverErrors.value = null
            handleUpdateRange(range.value)
        }




        const handleCreate = async () => {
            saving.value = true
            const valid = await isFormValid()

            if (!valid) {
                saving.value = false
                return
            }


            await store.dispatch('cromis-stack/create', stack.value)
                .then(response => {
                    refetch()
                    saving.value = false

                    myModal.value.hide()

                    context.root.$swal({
                        icon: 'success',
                        text: `Box has been added successfully!`,
                        showConfirmButton: true,
                        timer: 3000,
                        customClass: {
                            confirmButton: 'btn btn-primary',
                        },
                        buttonsStyling: false,
                    })
                })
                .catch(error => {
                    saving.value = false
                    errosOcurs.value = error.response.data
                    if (error.response.status === 422) {
                        serverErrors.value = error.response.data.errors
                    }
                    else {
                        context.root.$swal({
                            icon: 'error',
                            title: 'Server Error',
                            text: 'Something went wrong. See tech support',
                            showConfirmButton: true,
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                            buttonsStyling: false,
                        })
                    }
                })
        }



        const handleUpdateRange = async (item) => {
            console.log(item)
            saving.value = true
            const valid = await isFormValid()

            if (!valid) {
                saving.value = false
                return
            }

            await store.dispatch('cromis-stack/range', { id: item.id, data: item })
                .then(response => {
                    refetch()
                    saving.value = false

                    myModal.value.hide()

                    context.root.$swal({
                        icon: 'success',
                        text: `Changes to stack range has been saved successfully!`,
                        showConfirmButton: true,
                        timer: 3000,
                        customClass: {
                            confirmButton: 'btn btn-success',
                        },
                        buttonsStyling: false,
                    })
                })
                .catch(error => {
                    saving.value = false
                    errosOcurs.value = error.response.data
                    if (error.response.status === 422) {
                        serverErrors.value = error.response.data.errors
                    }
                    else {
                        context.root.$swal({
                            icon: 'error',
                            title: 'Server Error',
                            text: 'Something went wrong. See tech support',
                            showConfirmButton: true,
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                            buttonsStyling: false,
                        })
                    }
                })
        }


        const handleUpdate = async (item) => {
            console.log(item)
            saving.value = true
            const valid = await isFormValid()

            if (!valid) {
                saving.value = false
                return
            }

            await store.dispatch('cromis-stack/update', { id: item.id, data: item })
                .then(response => {
                    refetch()
                    saving.value = false

                    myModal.value.hide()

                    context.root.$swal({
                        icon: 'success',
                        text: `Changes to stack center ${response.data.code} ${response.data.name} has been saved successfully!`,
                        showConfirmButton: true,
                        timer: 3000,
                        customClass: {
                            confirmButton: 'btn btn-success',
                        },
                        buttonsStyling: false,
                    })
                })
                .catch(error => {
                    saving.value = false
                    errosOcurs.value = error.response.data
                    if (error.response.status === 422) {
                        serverErrors.value = error.response.data.errors
                    }
                    else {
                        context.root.$swal({
                            icon: 'error',
                            title: 'Server Error',
                            text: 'Something went wrong. See tech support',
                            showConfirmButton: true,
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                            buttonsStyling: false,
                        })
                    }
                })
        }


        return {
            // Data
            selections,
            formSelections,
            levels,
            children,
            stack,
            selectedOwner: null,
            addedElement: null,
            clientName: JSON.parse(localStorage.getItem('userData')).client,
            options: [
                { value: null, text: '-- Select Box Type --' },
                { value: '1', text: 'Bale Ticket' },
                { value: '2', text: 'Luggage' },
            ],
            fetch,
            columns,
            perPage,
            currentPage,
            totalRecords,
            from,
            to,
            meta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            records,
            refetch,
            resolveStatusVariant,

            // Methods

            required,
            saving,
            serverErrors,
            dataForm,
            myModal,
            myRangeModal,
            locationFilter,
            invokeCreateForm,
            invokeUpdateForm,
            invokeRangeUpdateForm,
            submit,
            submitUpdateRange,
            isFormValid,
            handleCreate,
            handleUpdate,
            handleUpdateRange,
            companies,
            societies,
            errosOcurs,
            changeRegion,
            getWarehouse,
            getStack,
            stackGrade,
            locations,
            locaChildren,
            client,
            locId,
            warehouses,
            cards: [
                { letter: 'A', countBales: '1,200', totalMass: '6,000,000', items: 'X1F0,X1F0,X1F0,X1F0X1F0,X1F0,X1F0,X1F0', bgColor: '#ffcccc' },
                { letter: 'B', countBales: '1,300', totalMass: '6,100,000', items: 'X1F1,X1F1,X1F1,X1F1X1F1,X1F1,X1F1,X1F1', bgColor: '#ccffcc' },
                { letter: 'C', countBales: '1,400', totalMass: '6,200,000', items: 'X1F2,X1F2,X1F2,X1F2X1F2,X1F2,X1F2,X1F2', bgColor: '#ccccff' },
                { letter: 'D', countBales: '1,500', totalMass: '6,300,000', items: 'X1F3,X1F3,X1F3,X1F3X1F3,X1F3,X1F3,X1F3', bgColor: '#ffffcc' },
                { letter: 'E', countBales: '1,600', totalMass: '6,400,000', items: 'X1F4,X1F4,X1F4,X1F4X1F4,X1F4,X1F4,X1F4', bgColor: '#ffccff' },
                { letter: 'F', countBales: '1,700', totalMass: '6,500,000', items: 'X1F5,X1F5,X1F5,X1F5X1F5,X1F5,X1F5,X1F5', bgColor: '#ccffff' },
                { letter: 'G', countBales: '1,800', totalMass: '6,600,000', items: 'X1F6,X1F6,X1F6,X1F6X1F6,X1F6,X1F6,X1F6', bgColor: '#ffcc99' },
                { letter: 'H', countBales: '1,900', totalMass: '6,700,000', items: 'X1F7,X1F7,X1F7,X1F7X1F7,X1F7,X1F7,X1F7', bgColor: '#99ccff' },
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
</style>